<template>
  <component-frame title="Typography">
    <div class="sg-page__heading">
      <div class="on-this-page">
        <div class="on-this-page-title">On This Page</div>
        <ul class="on-this-page-anchor-list">
          <li><a href="#" v-scroll-to="'#font'">Font</a></li>
          <li><a href="#" v-scroll-to="'#headings'">Headings</a></li>
          <li>
            <a href="#" v-scroll-to="'#leading-paragraph'">Leading Paragraph</a>
          </li>
          <li><a href="#" v-scroll-to="'#links'">Links</a></li>
          <li><a href="#" v-scroll-to="'#lists'">Lists</a></li>
          <li><a href="#" v-scroll-to="'#truncate'">Truncate Text</a></li>
          <li><a href="#" v-scroll-to="'#example'">Example</a></li>
        </ul>
      </div>
    </div>

    <h3 id="headings">Font</h3>
    <div class="sg-component">
      <div class="sg-component__blank">
        <p>myLTSA uses <strong>Noto Sans</strong> which is available in two weights with corresponding italics.</p>
        <p>
          Noto Sans is available on
          <a href="https://fonts.google.com/specimen/Noto+Sans" target="_blank" rel="noopener">Google Fonts</a>.
        </p>
        Base font size is <strong>16px</strong> for paragraphs, links, and lists. Navigation and tables are <strong>14px</strong>.
      </div>
    </div>

    <h3 id="headings">Headings</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <h1>Primary Page Heading (32px)</h1>
          <h2>Page Section Heading (24px)</h2>
          <h3>Page Section Sub-heading (18px)</h3>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
    &lt;h1&gt;Primary Page Heading&lt;/h1&gt;
    &lt;h2&gt;Page Section Heading&lt;/h2&gt;
    &lt;h3&gt;Page Section Sub-heading&lt;/h3&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>Use only one primary heading (h1) per page</li>
          <li>
            The primary heading should be short and adequately describe the content on the page
          </li>
          <li>Headings should follow a consistent hierachy</li>
          <li>Main page content should only use H1-H3</li>
          <li>Use title case for headings</li>
          <li>
            Use H4-H6 for secondary page content (related links, sidebar etc) and in the footer
          </li>
        </ul>
      </div>
    </div>

    <h3 id="leading-paragraph">Leading Paragraph</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <p class="lead">
            This is a leading paragraph. Ut laoreet vulputate neque nec imperdiet. Aenean aliquam pharetra mi rutrum eleifend. Ut sagittis porttitor imperdiet.
            Aenean varius pharetra odio ut laoreet. (20px)
          </p>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
    &lt;p class="lead"&gt;This is a leading paragraph. Ut laoreet vulputate neque nec imperdiet. Aenean aliquam pharetra mi rutrum eleifend. Ut sagittis porttitor imperdiet. Aenean varius pharetra odio ut laoreet.&lt;/p&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>Use only one leading paragraph per page</li>
          <li>
            Leading paragraphs would usually come after the primary heading and serve as a brief introduction to the content. Can also be used to emphasize a
            paragraph further down the page
          </li>
        </ul>
      </div>
    </div>

    <h3 id="links">Links</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <ul class="list-inline">
            <li class="list-inline-item">
              <a href="#" v-prevDef>Default Link</a>
            </li>
            <li class="list-inline-item">
              <a class="hover" href="#" v-prevDef>Link hover</a>
            </li>
            <li class="list-inline-item">
              <a class="focus" href="#" v-prevDef>Link focus</a>
            </li>
          </ul>
          <p>
            <a class="link-external" href="#" v-prevDef
              >This is an external link
              <svg class="icon icon-External-link">
                <use xlink:href="/icons/symbol-defs.svg#icon-External-link"></use></svg
            ></a>
          </p>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
    &lt;p&gt;&lt;a href="#"&gt;This is an internal link&lt;/a&gt;&lt;/p&gt;
    &lt;p&gt;&lt;a class="link-external" href="#"&gt;This is an external link &lt;svg class="icon icon-External-link"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-External-link"&gt;&lt;/use&gt;&lt;/svg&gt;&lt;/a&gt;&lt;/p&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>Link text should describe the expected content or action</li>
          <li>
            Using non descriptive link text like "click here" or "visit this link to&hellip;" is not recommended
          </li>
          <li>
            Only inline links should be underlined. Links in menus do not need to be underlined as they have a clear context
          </li>
        </ul>
      </div>
    </div>

    <h3 id="lists">Lists</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <ul>
            <li>This is an example of an unordered list</li>
            <li>Suspendisse a eros interdum tellus lobortis tempor</li>
            <li>Aliquam erat volutpat. Fusce non feugiat ipsum</li>
          </ul>
          <ol>
            <li>This is an example of an ordered list</li>
            <li>Morbi suscipit tempus fermentum. Proin augue enim</li>
            <li>Donec erat lacus, cursus a gravida sed, venenatis in arcu</li>
          </ol>
          <ul>
            <li>This is an example of a nested mixed list</li>
            <li>Morbi suscipit tempus fermentum. Proin augue enim</li>
            <ol>
              <li>This is an example of an ordered list</li>
              <li>Morbi suscipit tempus fermentum</li>
            </ol>
          </ul>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
    &lt;ul&gt;
      &lt;li&gt;This is an example of an unordered list&lt;/li&gt;
      &lt;li&gt;Suspendisse a eros interdum tellus lobortis tempor&lt;/li&gt;
      &lt;li&gt;Aliquam erat volutpat. Fusce non feugiat ipsum&lt;/li&gt;
    &lt;/ul&gt;
    &lt;ol&gt;
      &lt;li&gt;This is an example of an ordered list&lt;/li&gt;
      &lt;li&gt;Morbi suscipit tempus fermentum. Proin augue enim&lt;/li&gt;
      &lt;li&gt;Donec erat lacus, cursus a gravida sed, venenatis in arcu&lt;/li&gt;
    &lt;/ol&gt;
    &lt;ul&gt;
      &lt;li&gt;This is an example of a nested mixed list&lt;/li&gt;
      &lt;li&gt;Morbi suscipit tempus fermentum. Proin augue enim&lt;/li&gt;
      &lt;ol&gt;
        &lt;li&gt;This is an example of an ordered list&lt;/li&gt;
        &lt;li&gt;Morbi suscipit tempus fermentum&lt;/li&gt;
      &lt;/ol&gt;
    &lt;/ul&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>Lists should be sentence case with no full stop at the end</li>
          <li>
            Unordered lists should be used as a list of options, places, or actions where the order is unimportant
          </li>
          <li>
            Ordered lists should be used to describe a stepped process or a sequential action
          </li>
        </ul>
      </div>
    </div>

    <h3 id="truncate">Truncate and Brake Words</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <h4 class="mb-0">Truncate at 100px</h4>
          <p class="text-truncate text-truncate-100">
            This is a very long string of text that will be truncated to fit within a certain size
          </p>
          <h4 class="mt-3 mb-0">Truncate at 200px</h4>
          <p class="text-truncate text-truncate-200">
            This is a very long string of text that will be truncated to fit within a certain size
          </p>
          <h4 class="mt-3 mb-0">Truncate at 300px</h4>
          <p class="text-truncate text-truncate-300">
            This is a very long string of text that will be truncated to fit within a certain size
          </p>
          <h4 class="mt-3 mb-0">
            Truncate to container on mobile, 300px on larger screens
          </h4>
          <p class="text-truncate text-truncate text-truncate-sm-300">
            This is a very long string of text that will be truncated to fit within a certain size
          </p>
          <h4 class="mt-3 mb-0">Break Word</h4>
          <p class="break-word">
            ThisisaverylongstringwithoutspacesThisisaverylongstringwithoutspacesThisisaverylongstringwithoutspacesThisisaverylongstringwithoutspaces
          </p>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;h4 class="mb-0">Truncate at 100px&lt;/h4&gt;
&lt;p class="text-truncate text-truncate-100"&gt;This is a very long string of text that will be truncated to fit within a certain size&lt;/p&gt;
&lt;h4 class="mt-3 mb-0"&gt;Truncate at 200px&lt;/h4&gt;
&lt;p class="text-truncate text-truncate-200"&gt;This is a very long string of text that will be truncated to fit within a certain size&lt;/p&gt;
&lt;h4 class="mt-3 mb-0"&gt;Truncate at 300px&lt;/h4&gt;
&lt;p class="text-truncate text-truncate-300"&gt;This is a very long string of text that will be truncated to fit within a certain size&lt;/p&gt;
&lt;h4 class="mt-3 mb-0"&gt;Truncate to container on mobile, 300px on larger screens&lt;/h4&gt;
&lt;p class="text-truncate text-truncate text-truncate-sm-300"&gt;This is a very long string of text that will be truncated to fit within a certain size&lt;/p&gt;
&lt;h4 class="mt-3 mb-0"&gt;Break Word&lt;/h4&gt;
&lt;p class="break-word"&gt;This is a very long string of text that will be truncated to fit within a certain size&lt;/p&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>Add classes to truncate text at various sizes.</li>
          <li>Supported sizes are 100px, 200px, and 300px</li>
          <li>
            Use responsive variations to truncate text based on screen width
          </li>
          <li>
            In responsive tables the class .break-word can be used for smaller screens combined with .text-truncate.text-truncate-md-200 to make the text fit in
            the cell on larger screens
          </li>
          <li>
            There's no solution that will best fit every scenario. Experiment based on the context and expected user input
          </li>
        </ul>
      </div>
    </div>

    <h3 id="example">Example</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <h1>Order a Document or Plan</h1>
          <p class="lead">
            Duis faucibus nibh sodales sem fermentum mollis. Quisque vel lorem erat. Maecenas ultricies, sapien eu tincidunt ultricies, mauris lorem molestie
            sem, ut posuere purus nibh at dolor.
          </p>
          <h2>Before You Order</h2>
          <p>
            Vestibulum ut feugiat mauris, vel mollis leo. Proin vitae accumsan tellus, et congue orci. Sed pharetra dui vitae lorem venenatis, quis dictum orci
            pharetra. Pellentesque a eros fringilla, rutrum risus vel, malesuada ex. Vivamus tincidunt augue ac erat interdum semper.
            <a href="#" v-prevDef
              >Vivamus euismod risus
              <svg class="icon icon-External-link">
                <use xlink:href="/icons/symbol-defs.svg#icon-External-link"></use></svg
            ></a>
            id augue finibus, et posuere est fringilla. Interdum et malesuada fames ac ante ipsum primis in faucibus.
          </p>
          <h2>Ways to Order Documents and Plans</h2>
          <h3>Hire a Professional to Assist</h3>
          <p>
            Aliquam eget erat orci. In fermentum sed nisi ut eleifend. Vestibulum
            <a class="link-glossary" href="#" v-prevDef>et augue felis</a>. Sed tincidunt semper neque gravida imperdiet. Vestibulum sed volutpat diam. Nulla
            sed nunc ligula. Nunc efficitur augue nec aliquet eleifend. Suspendisse molestie tempus risus vel dictum. Nunc sed justo vitae felis consectetur
            sagittis. Sed ultricies imperdiet purus ac imperdiet.
          </p>
          <h3>Request at a Land Title Office Counter</h3>
          <p>
            Aliquam eget erat orci. In fermentum sed nisi ut eleifend. Vestibulum et augue felis. Sed tincidunt semper neque gravida imperdiet. Vestibulum sed
            volutpat diam. Nulla sed nunc ligula.
          </p>
          <p>To request:</p>
          <ol>
            <li>Know the identifying number for the document or plan</li>
            <li>
              Contact the LTSA Customer Service Centre at&nbsp;1-877-577-LTSA&nbsp;(5872)&nbsp;to confirm the record has been imaged and the fees to be paid.
              The printing fee will vary depending on the length of the record.
            </li>
            <li>
              After you are informed of the fees owing, you may proceed with your mail-in request
            </li>
          </ol>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
    &lt;h1&gt;Order a Document or Plan&lt;/h1&gt;
    &lt;p class="lead"&gt;Duis faucibus nibh sodales sem fermentum mollis. Quisque vel lorem erat. Maecenas ultricies, sapien eu tincidunt ultricies, mauris lorem molestie sem, ut posuere purus nibh at dolor.&lt;/p&gt;
    &lt;h2&gt;Before You Order&lt;/h2&gt;
    &lt;p&gt;Vestibulum ut feugiat mauris, vel mollis leo. Proin vitae accumsan tellus, et congue orci. Sed pharetra dui vitae lorem venenatis, quis dictum orci pharetra. Pellentesque a eros fringilla, rutrum risus vel, malesuada ex. Vivamus tincidunt augue ac erat interdum semper. &lt;a href="#"&gt;Vivamus euismod risus &lt;svg class="icon icon-External-link"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-External-link"&gt;&lt;/use&gt;&lt;/svg&gt;&lt;/a&gt; id augue finibus, et posuere est fringilla. Interdum et malesuada fames ac ante ipsum primis in faucibus.&lt;/p&gt;
    &lt;h2&gt;Ways to Order Documents and Plans &lt;/h2&gt;
    &lt;h3&gt;Hire a Professional to Assist&lt;/h3&gt;
    &lt;p&gt;Aliquam eget erat orci. In fermentum sed nisi ut eleifend. Vestibulum &lt;a class="link-glossary" href="#"&gt;et augue felis&lt;/a&gt;. Sed tincidunt semper neque gravida imperdiet. Vestibulum sed volutpat diam. Nulla sed nunc ligula. Nunc efficitur augue nec aliquet eleifend. Suspendisse molestie tempus risus vel dictum. Nunc sed justo vitae felis consectetur sagittis. Sed ultricies imperdiet purus ac imperdiet.&lt;/p&gt;
    &lt;h3&gt;Request at a Land Title Office Counter&lt;/h3&gt;
    &lt;p&gt;Aliquam eget erat orci. In fermentum sed nisi ut eleifend. Vestibulum et augue felis. Sed tincidunt semper neque gravida imperdiet. Vestibulum sed volutpat diam. Nulla sed nunc ligula.&lt;/p&gt;
    &lt;p&gt;To request:&lt;/p&gt;
    &lt;ol&gt;
      &lt;li&gt;Know the identifying number for the document or plan&lt;/li&gt;
      &lt;li&gt;Contact the LTSA Customer Service Centre at&nbsp;1-877-577-LTSA&nbsp;(5872)&nbsp;to confirm the record has been imaged and the fees to be paid. The printing fee will vary depending on the length of the record.&lt;/li&gt;
      &lt;li&gt;After you are informed of the fees owing, you may proceed with your mail-in request&lt;/li&gt;
    &lt;/ol&gt;
                </code></pre>
        </div>
      </div>
    </div>
  </component-frame>
</template>

<script>
export default {
  name: "typography",
  data() {
    return {
      title: "Typography",
    };
  },
};
</script>

<style lang="scss">
@import "../../../node_modules/bootstrap/scss/_functions.scss";
@import "../../../node_modules/bootstrap/scss/_variables.scss";
@import "../../../node_modules/bootstrap/scss/_mixins.scss";
@import "../../scss/ltsa-styles/_ltsa-variables.scss";

a.hover {
  color: $tertiary;
  text-decoration: underline;
}
</style>
